import OptimizedImage from "hooks/OptimizedImage";
import React from "react";
import { Link } from "gatsby";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "@reach/router";

export default function MidSubText({ data }) {
  const location = useLocation();
  const currentLink = location.href;

  return (
    <>
      <div
        className={`px-6 sm:px-10  py-4 ${
          data?.service_links?.length > 0 || data?.language_links?.length > 0
            ? "bg-black"
            : ""
        }`}
      >
        {data?.service_links?.length > 0 && (
          <>
            <div className="text-white w-full sm:w-8/12 m-auto text-14 sm:text-new">
              {"Services"}
              <FontAwesomeIcon icon={faAngleRight} className="ml-3" />
              {data?.service_links.map(({ link, text }, index) => (
                <>
                  <Link
                    className=" py-2  ml-2 text-lightBlue cursor-pointer"
                    to={link}
                  >
                    {text}
                  </Link>
                  {index !== data?.service_links.length - 1 && (
                    <FontAwesomeIcon icon={faAngleRight} className="ml-3" />
                  )}
                </>
              ))}
            </div>
          </>
        )}
        {/* {data?.language_links?.length > 0 && (
          <>
            <div className="text-white w-full sm:w-8/12 m-auto text-14 sm:text-new">
              {"Languages"}
              <FontAwesomeIcon icon={faAngleRight} className="ml-3" />
              {data?.language_links.map(({ link, text }, index) => (
                <>
                  <Link
                    className=" py-2 ml-2 text-lightBlue cursor-pointer"
                    to={link}
                  >
                    {text}
                  </Link>
                  {index !== data?.language_links?.length - 1 && (
                    <FontAwesomeIcon icon={faAngleRight} className="ml-3" />
                  )}
                </>
              ))}
            </div>
          </>
        )} */}
      </div>
      <div className="flex items-center justify-center  w-full recent-wrapper">
        <div className="flex justify-evenly max-w-screen-2xl w-full xl:w-10/12 flex-col md:flex-row">
          {/* <div className="md:w-7/12 w-full px-6 pt-24 md:pt-36"> */}
          <div className="md:w-7/12 w-full px-6">
            <h2 className=" mt-12 text-lightBlue font-secondary">
              {data.title_x || data.seo_h1 || data.seo_project_title}
            </h2>
            <span className=" mt-1 mb-3 text-blackNew-400 font-secondary">
              <i className="text-base">
                {moment(data.seo_project_create_date).format("MMMM DD, YYYY")}
              </i>
            </span>
            {/* <h3 className="mb-5 text-black font-medium font-secondary">
              <i className="text-lg">By Ofer Tirosh</i>
            </h3> */}
            {data.cover_image !== "" && (
              <OptimizedImage
                src={data.cover_image}
                alt={data.alt_tag}
                className="w-full object-cover h-auto lg:h-[370px]"
                // className="w-full object-cover h-auto lg:h-[370px] mb-8"
              />
            )}
            <div
              className="font-normal text-base tracking-normal font-opensans text-white  blog-data mb-8 mt-8"
              dangerouslySetInnerHTML={{ __html: data.seo_project_content }}
            />
          </div>
          <div className="md:w-1/4 w-full md:mt-36 mt-11 sm:border-l sm:border-gray sm:pl-6 h-2/3">
            <p
              className="text-black sm:text-3xl mt-10  pb-8 font-primary font-bold text-center md:text-left"
              style={{ fontWeight: "700", color: "black", fontSize: "32px" }}
            >
              Why choose us
            </p>
            <div className="sm:w-9/12 text-center mb-7">
              <OptimizedImage
                src="https://tomedes.gumlet.io/frontend/images/why-us-img/24-7-human-support.svg"
                alt=""
                className="m-auto inline-block w-20 h-[91px]"
                lazyload={false}
              />
              <p className="text-lg font-primary text-white mt-4">
                24/7 Human Support
              </p>
            </div>
            <div className="sm:w-9/12 text-center mb-7">
              <OptimizedImage
                src="https://tomedes.gumlet.io/frontend/images/why-us-img/quality-guaranteed-color.svg"
                alt=""
                className="m-auto inline-block w-20 h-[103px]"
                lazyload={false}
              />
              <p className="text-lg font-primary text-white mt-4">
                1 Year Guarantee
              </p>
            </div>
            <div className="sm:w-9/12 text-center mb-7">
              <OptimizedImage
                src="https://tomedes.gumlet.io/frontend/images/why-us-img/50000-business.svg"
                alt=""
                className="m-auto inline-block w-20 h-20"
                lazyload={false}
              />
              <p className="text-lg font-primary text-white mt-4">
                95,000 Business Customers
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="relative bg-[#f7f7f7] pt-4 pb-14">
        <span className="text-4xl font-primary font-bold leading-[60px] text-center text-[#000000] 744px:block hidden">
          Experience the Power of Machine Translation
        </span>

        <iframe
          width="100%"
          height="600"
          src={`https://www.machinetranslation.com/embedded/translation?pageLink=${currentLink}`}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="744px:block hidden"
        ></iframe>
      </div>
    </>
  );
}
